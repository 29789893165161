import * as React from "react";
import {
  Container,
  Box,
  Chip,
  Card,
  CardContent,
  Typography,
  CircularProgress,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { DataGrid } from "@mui/x-data-grid";
import AccessContainer from "../common/AccessContainer";

const Page = (props) => {
  const { cosmosId } = props;
  const [conversations, setConversations] = React.useState([]);
  const [parentDoc, setParentDoc] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const fetchConversations = async () => {
    const res = await fetch("/api/MessageHub/GetMessageHubMessages", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ cosmosId }),
    });
    const data = await res.json();
    if (data.length > 0) {
      setParentDoc(data[0]);
      setConversations(data.slice(1));
    }
    setLoading(false);
  };

  React.useEffect(() => {
    fetchConversations();
  }, [cosmosId]);

  const viewConversation = (conversationId) => {
    window.location.href = `/MessageHub/Conversation/${conversationId}`;
  };

  const renderMessagePreview = (replies) => {
    if (!replies?.length) return "No messages yet";
    const lastMessage = replies[replies.length - 1].message;
    const words = lastMessage.split(" ");
    const preview = words.slice(0, 10).join(" ");
    return words.length > 10 ? `${preview}...` : preview;
  };

  const renderDate = (timestamp) => {
    return timestamp ? new Date(timestamp * 1000).toLocaleString() : "N/A";
  };

  const renderRecipient = (recipientName, to) => {
    return recipientName || `+${to}`;
  };

  const columns = [
    {
      field: "recipientName",
      headerName: "To",
      width: 200,
      renderCell: (params) => renderRecipient(params.row.recipientName, params.row.to),
    },
    {
      field: "messagePreview",
      headerName: "Message",
      width: 300,
      renderCell: (params) => renderMessagePreview(params.row.replies),
    },
    {
      field: "isSeen",
      headerName: "Read",
      width: 100,
      renderCell: (params) => !params.row.isSeen && <CircleIcon style={{ color: "#3374E2", fontSize: 15 }} />,
    },
    {
      field: "lastMessageAt",
      headerName: "Last Message",
      width: 200,
      renderCell: (params) => renderDate(params.row.lastMessageAt),
    },
  ];

    const CustomHeader = () => {
      return (
        <div style={{ padding: '16px', backgroundColor: '#f5f5f5', borderBottom: '1px solid #e0e0e0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6"> Related Conversations ({conversations.length})</Typography>
        </div>
      );
    };


  return (
    <AccessContainer customStyle={{ maxWidth: "100%", width: "100%" }} allowedRoles={["NexusAdmin", "MessageHub"]}>
      <Container>
        <br />
        {parentDoc && (
          <>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 3 }}>
              <Chip label={<Typography variant="body1"><strong>User:</strong> {parentDoc.user}</Typography>} color="default" />
              <Chip label={<Typography variant="body1"><strong>From:</strong> +{parentDoc.from}</Typography>} color="default" />
              <Chip label={<Typography variant="body1"><strong>Groups:</strong> {parentDoc.groups.length > 0 ? parentDoc.groups : "n/a"}</Typography>} color="default" />
              <Chip label={<Typography variant="body1"><strong>Recipients:</strong> {parentDoc.recipients.length}</Typography>} color="default" />
              <Chip label={<Typography variant="body1"><strong>Sent At:</strong> {new Date(parentDoc._ts * 1000).toLocaleString()}</Typography>} color="default" />
            </Box>
            <Card sx={{ padding: 1, borderRadius: "24px 24px 6px 24px", backgroundColor: "#1976D2", color: "#fff", marginBottom: 2 }}>
              <CardContent>
                <Typography variant="h6" component="span">
                  {parentDoc.message}
                </Typography>
              </CardContent>
            </Card>
          </>
        )}

        {loading ? (
          <div style={{ textAlign: "center", padding: "50px" }}>
            <CircularProgress />
            <Typography variant="body2" style={{ marginTop: "10px" }}>Loading...</Typography>
          </div>
        ) : conversations.length > 0 ? (
          <>
            <div style={{ height: 800, width: "100%" }}>
              <DataGrid
                slots={{
                  toolbar: CustomHeader,
                }}
                rows={conversations}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                onRowClick={(params) => viewConversation(params.row.conversationId)}
                getRowId={(row) => row.conversationId} // Ensure unique key for each row
              />
            </div>
          </>
        ) : (
          <div style={{ textAlign: "center", padding: "50px", color: "#757575" }}>
            <Typography variant="h6">No Conversations Available</Typography>
            <Typography variant="body2">There are currently no messages to display. Please try again later.</Typography>
          </div>
        )}
      </Container>
    </AccessContainer>
  );
};

export default Page;
