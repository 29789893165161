import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Button,
  Box,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useSessionStorage } from "../../hooks/useSessionStorage";
import SendIcon from '@mui/icons-material/Send';
import AccessContainer from "../common/AccessContainer";

const ConversationPage = ({ conversationId }) => {
  const [newReply, setNewReply] = useState("");
  const [conversation, setConversation] = useState(null);
  const [user] = useSessionStorage("user", null);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const scrollRef = useRef(null); // Reference for the scroll container

  const fetchConversation = async () => {
    const res = await fetch("/api/MessageHub/GetMessageHubMessages", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ conversationId }),
    });
    const data = await res.json();
    if (data.length > 0) {
      setConversation(data[0]);
    }
  };

  useEffect(() => {
    markAsRead();
  }, [])

  useEffect(() => {
    fetchConversation();

    const intervalId = setInterval(() => {
      fetchConversation();
    }, 10000);

    return () => clearInterval(intervalId);
  }, [conversationId]);

  const markAsRead = async () => {
    await fetch("/api/MessageHub/UpdateMessageHubMessage", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ conversationId }),
    });
  };

  const handleSendReply = () => {
    if (newReply.trim() === "") return;
    setConfirmOpen(true);
  };

  const handleConfirmSend = async () => {
    const optimisticReply = {
      from: conversation.from,
      to: conversation.replies[0]?.to,
      message: newReply,
      time: new Date().toISOString(),
    };

    setConversation((prevConversation) => ({
      ...prevConversation,
      replies: [...prevConversation.replies, optimisticReply],
    }));

    setNewReply("");
    setConfirmOpen(false);

    try {
      await fetch("/api/MessageHub/CreateMessageHubMessage", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sender: conversation.from,
          messageBody: optimisticReply.message,
          tab: "Paste List",
          pasteList: `${conversation.to},`,
          user: user.userDetails,
          action: "REPLY",
        }),
      });
    } catch (error) {
      console.error("Failed to send reply", error);
    }
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [conversation]);

  const displayRecipient = conversation ? `${conversation?.recipientName ? conversation.recipientName + ' ' : ''}(+${conversation.to})` : '';


  return (
        <AccessContainer
          customStyle={{ maxWidth: "100%", width: "100%" }}
          allowedRoles={["NexusAdmin", "MessageHub"]}
        >
          <Container sx={{ padding: "2rem 0", maxHeight: "100vh", display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" gutterBottom>
            {conversation && displayRecipient}
            </Typography>
            <Box
              ref={scrollRef}
              sx={{
                flex: 1,
                overflowY: "auto",
                display: "flex",
                flexDirection: "column-reverse",
                padding: "1rem 0",
                backgroundColor: "#fff",
                borderRadius: "8px",
                maxHeight: "40vh",
              }}
            >
              {conversation?.replies?.length ? (
                <Box component="ul" sx={{ listStyle: "none", padding: 0, margin: 0 }}>
                  {conversation.replies.map((reply, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        justifyContent: reply.from === conversation.from ? "flex-end" : "flex-start",
                        marginBottom: 2,
                      }}
                    >
                      <Box
                        sx={{
                          maxWidth: "75%",
                          padding: 1,
                          borderRadius: reply.from === conversation.from ? "18px 18px 0 18px" : "18px 18px 18px 0",
                          backgroundColor: reply.from === conversation.from ? "#1976D2" : "#f0f0f0",
                          color: reply.from === conversation.from ? "#fff" : "#000",
                          wordBreak: "break-word",
                        }}
                      >
                        <Typography variant="subtitle2" fontWeight="bold">
                          +{reply.from}
                        </Typography>
                        <Typography variant="body1">{reply.message}</Typography>
                        <Typography variant="caption" display="block" sx={{ marginTop: "4px" }}>
                          {new Date(reply.time).toLocaleString()}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              ) : (
                <Typography variant="body1">No replies</Typography>
              )}
            </Box>
            <TextField
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              label="Write a reply..."
              value={newReply}
              onChange={(e) => setNewReply(e.target.value)}
              sx={{ marginTop: "16px" }}
            />
            <Box display="flex" justifyContent="flex-end" sx={{ marginTop: "16px" }}>
              <Button endIcon={<SendIcon />} variant="contained" color="primary" onClick={handleSendReply}>
                Send
              </Button>
            </Box>
            <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
              <DialogTitle>Confirm Message</DialogTitle>
              <DialogContent>
                <DialogContentText>Are you sure you want to send this message?</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setConfirmOpen(false)} color="secondary">
                  Cancel
                </Button>
                <Button onClick={handleConfirmSend} color="primary" autoFocus>
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
          </Container>
        </AccessContainer>
  );
};

export default ConversationPage;
