import React, { useEffect, useState } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import MUIDataTable from "mui-datatables";
import AccessContainer from "../common/AccessContainer";

function PartnerPracticePortalAudit(props) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    { name: "description" },
    { name: "user" },
    { name: "practiceId" },
    {
      name: "ModifiedFields",
      options: {
        customBodyRender: (value, tableMeta, updateValue) =>
          JSON.stringify(data[tableMeta.rowIndex]),
      },
    },
    {
      name: "_ts",
      label: "Modified",
      options: {
        customBodyRender: (value, tableMeta, updateValue) =>
          `${new Date(value * 1000).toLocaleDateString()} ${new Date(
            value * 1000
          ).toLocaleTimeString()}`,
      },
    },
  ];

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setIsLoading(true);
    fetch(`/api/HelixAudit/PartnerPracticeAudit/`)
      .then((response) => response.json())
      .then((items) => {
        setData(items);
        setIsLoading(false);
        console.log(items);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  return (
    <AccessContainer allowedRoles={["NexusAdmin", "HelixAudit"]}>
      <Row>
        <Col md={4}>
          {/* <InputGroup className="mb-3">
            <FormControl
              placeholder="Enter a Case Id"
              type="number"
              aria-label="Enter a Case Id"
              aria-describedby="button-Search"
              value={caseId}
              onChange={(e) => setCaseId(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <Button
              variant="primary"
              id="button-Search"
              onClick={() => {
                loadData(caseId);
              }}
            >
              Search
            </Button>
          </InputGroup> */}
        </Col>
        <Col> {isLoading && <Spinner animation="border" />}</Col>
      </Row>
      {data && (
        <Row>
          <MUIDataTable
            data={data}
            columns={columns}
            title={`Partner Practice Portal Audit`}
            options={{
              selectableRows: "none",
              responsive: "standard",
            }}
          />
        </Row>
      )}
    </AccessContainer>
  );
}
export default PartnerPracticePortalAudit;
