import * as React from "react";
import { Container, FormControl, Select, MenuItem, Typography, CircularProgress } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { useSessionStorage } from "../../hooks/useSessionStorage";
import CircleIcon from '@mui/icons-material/Circle';
import AccessContainer from "../common/AccessContainer";

const Page = () => {
  const [conversations, setConversations] = React.useState([]);
  const [senders, setSenders] = React.useState([]);
  const [sender, setSender] = React.useState(null);
  const [user] = useSessionStorage("user", null);
  const [loading, setLoading] = React.useState(false); 

  const fetchConversations = React.useCallback(async (senderNumber) => {
    setLoading(true);
    const res = await fetch("/api/MessageHub/GetMessageHubMessages", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ sender: senderNumber }),
    });

    if (res.ok) {
      const data = await res.json();
      setConversations(data);
    } else {
      console.error('Error fetching conversations:', res.statusText);
    }
    setLoading(false); 
  }, []);

  const fetchSenders = React.useCallback(async () => {
    setLoading(true); 
    try {
      const response = await fetch("/api/MessageHub/GetMessageHubSenders", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setSenders(data);
    } catch (error) {
      console.error("Error fetching senders:", error);
    }
    setLoading(false); 
  }, []);

  React.useEffect(() => {
    fetchSenders();
  }, [fetchSenders]);

  React.useEffect(() => {
    if (senders.length > 0 && user) {
      const matchedSender = senders.find(s => s.email === user.userDetails);
      setSender(matchedSender ? matchedSender.number : null);
    }
  }, [senders, user]);

  React.useEffect(() => {
    if (sender) {
      fetchConversations(sender);
    }
  }, [sender, fetchConversations]);

  const columns = [
    {
      field: 'recipientName',
      headerName: 'To',
      width: 200,
      renderCell: (params) => renderRecipient(params.row.recipientName, params.row.to),
    },
    {
      field: 'from',
      headerName: 'From',
      width: 200,
      renderCell: (params) => `+${params.row.from}`,
    },
    {
      field: 'messagePreview',
      headerName: 'Message',
      width: 300,
      renderCell: (params) => renderMessagePreview(params.row.replies),
    },
    {
      field: 'isSeen',
      headerName: 'Read',
      width: 150,
      renderCell: (params) => !params.row.isSeen && <CircleIcon style={{ color: '#3374E2', fontSize: 15 }} />,
    },
    {
      field: 'lastMessageAt',
      headerName: 'Last Message',
      width: 200,
      renderCell: (params) => renderDate(params.row.lastMessageAt),
    },
  ];

  const renderMessagePreview = (replies) => {
    if (!replies?.length) return 'No messages yet';
    const lastMessage = replies[replies.length - 1].message;
    const preview = lastMessage.split(' ').slice(0, 10).join(' ');
    return lastMessage.split(' ').length > 10 ? `${preview}...` : preview;
  };

  const renderDate = (timestamp) => timestamp ? new Date(timestamp * 1000).toLocaleString() : 'N/A';

  const renderRecipient = (recipientName, to) => recipientName || `+${to}`;

  const CustomHeader = () => {
    return (
      <div style={{ padding: '16px', backgroundColor: '#f5f5f5', borderBottom: '1px solid #e0e0e0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">Conversations</Typography>
      </div>
    );
  };

  return (
    <AccessContainer
      customStyle={{ maxWidth: "100%", width: "100%" }}
      allowedRoles={["NexusAdmin", "MessageHub"]}
    >
      <Container>
        <FormControl fullWidth>
          <br />
          <Typography>Sender</Typography>
          <Select
            labelId="simple-select-label"
            id="simple-select"
            value={sender || ''}
            onChange={(e) => setSender(e.target.value)}
          >
            {senders.map((sender) => (
              <MenuItem key={sender.number} value={sender.number}>
                +{sender.number} ({sender.email})
              </MenuItem>
            ))}
          </Select>
        </FormControl>
  
        {loading ? (
          <div style={{ textAlign: 'center', padding: '50px' }}>
            <CircularProgress />
            <Typography variant="body2" style={{ marginTop: '10px' }}>Loading...</Typography>
          </div>
        ) : conversations.length > 0 ? (
          <div style={{ height: 600, width: '100%', marginBottom: '40px' }}>
            <br />
            <DataGrid
              slots={{
                toolbar: CustomHeader,
              }}
              getRowId={(row) => row.conversationId} 
              rows={conversations}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              onRowClick={(params) =>
                window.location.href = `/MessageHub/Conversation/${params.row.conversationId}`
              }
            />
          </div>
        ) : (
          <div style={{ textAlign: 'center', padding: '50px', color: '#757575' }}>
            <Typography variant="h6">No Conversations Available</Typography>
            <Typography variant="body2">There are currently no messages to display. Please try again later.</Typography>
          </div>
        )}
      </Container>
    </AccessContainer>
  );
  
};

export default Page;
