import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from '@mui/material/Checkbox';
import { Button, Box, Card, CardContent, Typography, Grid, FormGroup, Tab, Tabs, FormControl,  Divider,   Dialog,  DialogActions,DialogContent ,DialogTitle, Alert, Switch   } from "@mui/material";
import { useSessionStorage } from "../../hooks/useSessionStorage";
import SendIcon from '@mui/icons-material/Send';
import { Spinner } from "react-bootstrap";
import useNotification from "../../hooks/sendNotification";
import { DataGrid } from "@mui/x-data-grid";
import AccessContainer from "../common/AccessContainer";


const Page = () => {
  const maxCharCount = 480;
  const [view, setView] = React.useState("My messages"); // All or My messages

  const [groups, setGroups] = React.useState([]);
  const [selectedGroups, setSelectedGroups] = React.useState([]);

  const [senders, setSenders] = React.useState([])
  const [sender, setSender] = React.useState(senders[0]);
  
  const [user] = useSessionStorage("user", null);
  const [tab, setTab] = React.useState("Groups");
  const [pasteList, setPasteList] = React.useState("");
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  
  const [messageBody, setMessageBody] = React.useState("");
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [sendAt, setSendAt] = React.useState(null);
  
  const [templates, setTemplates] = React.useState([]);
  const [template, setTemplate] = React.useState(null);
  const [messages, setMessages] = React.useState([]);

  const [msg, sendNotification] = useNotification();



  const columns = [
    { field: "message", headerName: "Message", flex: 2, renderCell: (params) => renderMessagePreview(params.value) },
    { field: "recipients", headerName: "Recipients", flex: 1, renderCell: (params) => params.value.length },
    { field: "groups", headerName: "Groups", flex: 1 },
    { field: "sent", headerName: "Sent", flex: 1, valueGetter: (params) => params.row._ts ? new Date(params.row._ts * 1000).toLocaleString() : "N/A" },
    { field: "user", headerName: "Created by", flex: 1 },
  ];
  


  const handleTemplateChange = (event) => {
    const selectedTemplateId = event.target.value;
    const selectedTemplate = templates.find((template) => template.id === selectedTemplateId);
    if (selectedTemplate) {
      setTemplate(selectedTemplateId); // Update template state
      setMessageBody(selectedTemplate.message); // Populate message body with template content
    }
  };

  const handleChange = (group) => {
    setSelectedGroups((prevSelected) => {
      const isSelected = prevSelected.find((item) => item.id === group.id);
      return isSelected
        ? prevSelected.filter((item) => item.id !== group.id) // Remove if already selected
        : [...prevSelected, group]; // Add if not selected
    });
  };

  const handleSend = () => {
    const isPasteListInvalid = tab === 'Paste List' && !pasteList;
    const isGroupsInvalid = tab === 'Groups' && !selectedGroups.length;
  
    if (!messageBody || !sender) {
      sendNotification({ msg: "Please fill in all fields" });
      return;
    } else if (isPasteListInvalid || isGroupsInvalid) {
      sendNotification({ msg: "Please specify recipients" });
      return;
    }
  
    setConfirmOpen(true);
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      setConfirmOpen(false);
      sendNotification({ msg: "Sending message, please wait..." });
  
      const response = await fetch("/api/MessageHub/CreateMessageHubMessage", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          sender,
          messageBody,
          pasteList,
          sendAt,
          selectedGroups,
          user: user.userDetails,
          action: "BULKSMS",
          tab,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
  
      const data = await response.json();
  
      setMessages((prevMessages) => [data, ...prevMessages]);
      setPasteList("");
      setMessageBody("");
      setTemplate(null);
      setSelectedGroups([]);
  
      sendNotification({ msg: "Message sent successfully." });
    } catch (error) {
      console.error("Error sending message:", error);
      alert("Failed to send message. Please try again or contact support.");
    } finally {
      setIsSubmitting(false);
    }
  };
  
  

  const handleMessageBodyChange = (event) => {
    if (event.target.value.length > maxCharCount) return;
    setMessageBody(event.target.value);
    setTemplate(null); 
  }

  
  const fetchMessages = async () => {
    try {
      const response = await fetch("/api/MessageHub/GetMessageHubMessages", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          user: user.userDetails,
        },
        method: "POST",
        body: JSON.stringify({ view: view }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setMessages(data);
      setTemplates(data); // Populate templates with previous message data so user can reuse
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const fetchGroups = async () => {
    try {
      const response = await fetch("/api/MessageHub/GetMessageHubGroups");
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setGroups(data);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const fetchSenders = async () => {
    try {
      const response = await fetch("/api/MessageHub/GetMessageHubSenders", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          user: user.userDetails,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setSenders(data);
    } catch (error) {
      console.error("Error fetching numbers:", error);
    }
  };

  const goToMessage = (id) => {
    window.location.href = `/MessageHub/Bulk/${id}`;
  }
  


  React.useEffect(() => {
    fetchGroups();
    fetchMessages();
    fetchSenders();
    sendNotification({ msg: "Loading data, please wait..." });
  }, []);
  
  React.useEffect(() => {
    sendNotification({ msg: "Loading data, please wait..." });
    fetchMessages();
  }, [view]);

  const handleViewChange = (e) => {
    setView(e.target.checked ? "My messages" : "All messages");
  }


  const renderMessagePreview = (message) => {
    if (!message) return 'No messages yet';
    const words = message.split(' ');
    const preview = words.slice(0, 10).join(' ');
    return words.length > 10 ? `${preview}...` : preview;
  };
  
  
  
  return (
    <>
        <AccessContainer
          customStyle={{ maxWidth: "100%", width: "100%" }}
          allowedRoles={["NexusAdmin", "MessageHub"]}
        >
      <Box sx={{ padding: 2 }}>
      <Alert severity="info" sx={{ marginBottom: 2 }}>
        To send SMS messages, use the groups synced from{" "}
        <a
          href="https://vetsnowteam.myfreshworks.com/crm/sales/contacts/view/30001934561?per_page=25"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'underline' }}
        >
          Freshsales
        </a>
        . Alternatively, you can use the paste list to send messages to specific numbers. For a detailed guide, click{" "}
        <a
          href="https://servicedesk.vets-now.com/a/solutions/articles/50000201510"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'underline' }}
        >
          here
        </a>.
      </Alert>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Card sx={{ height: "100%" }}>
              <CardContent>
                <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)}>
                  <Tab label="Groups" value={"Groups"} />
                  <Tab label="Paste List" value={"Paste List"} />
                </Tabs>
                {tab === "Groups" && (
                  <Box sx={{ p: 3, maxHeight: '340px', overflow: 'auto' }}>
                    <FormGroup>
                      {groups.map((group) => (
                        <React.Fragment key={group.id}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedGroups.includes(group)}
                                onChange={() => handleChange(group)}
                              />
                            }
                            label={group.name}
                          />
                          <Divider />
                        </React.Fragment>
                      ))}
                    </FormGroup>
                  </Box>
                )}
                {tab === "Paste List" && (
                  <Box sx={{ p: 3 }}>
                    <textarea
                      placeholder="Enter UK numbers, separated by commas or new lines"
                      onChange={(e) => setPasteList(e.target.value)}
                      value={pasteList}
                      style={{
                        height: "200px",
                        width: "100%",
                        border: "1px solid rgb(183, 183, 183)",
                        borderRadius: "4px",
                      }}
                    />
                  </Box>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card sx={{ height: "100%" }}>
              <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <FormControl fullWidth>
                  <Typography>Sender</Typography>
                  <Select
                    labelId="simple-select-label"
                    id="simple-select"
                    value={sender}
                    onChange={(e) => setSender(e.target.value)}
                  >
                    {senders.map((sender) => (
                      <MenuItem key={sender.number} value={sender.number}>
                        +{sender.number} ({sender.email})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl 
                >
                  <Typography>Previous messages</Typography>
                  <Select
                    labelId="template-select-label"
                    id="template-select"
                    value={template || ""}
                    onChange={handleTemplateChange}
                    displayEmpty
                    sx={{
                      maxWidth: "100%",
                      
                    }}
                    >
                    {templates.map((template) => (
                      <MenuItem
                      sx={{
                        maxWidth: "100%",
                      }}
                      key={template.id} 
                      value={template.id}>
                        {template.message}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <Typography>Message</Typography>
                  <textarea
                    placeholder="Type your message here"
                    onChange={handleMessageBodyChange}
                    value={messageBody}
                    style={{
                      height: "140px",
                      width: "100%",
                      border: "1px solid rgb(183, 183, 183)",
                      borderRadius: "4px",
                      padding: "4px",
                    }}
                  />
                  <p>{messageBody.length} / {maxCharCount}</p>
                </FormControl>
                <Button
                  onClick={handleSend}
                  endIcon={isSubmitting ? <Spinner animation="border" size="sm" /> : <SendIcon />}
                  loading={isSubmitting}
                  loadingPosition="end"
                  variant="contained"
                >
                  {
                    !isSubmitting ? "Send" : "Sending message..."
                  }
                </Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h5">{view === "All messages" ? "All messages" : view === "My messages" ? "My messages" : null}</Typography>
                <FormControl>
                        <FormGroup>
                          <FormControlLabel
                            value="My messages"
                            control={
                              <Switch
                                color="primary"
                                onChange={handleViewChange}
                                defaultChecked
                              />
                            }
                            label={`${view === "All" ? "All messages" : "My messages"}` }
                            labelPlacement="start"
                          />
                        </FormGroup>
                      </FormControl>
              </Box>
      <DataGrid
      rows={messages.map((message) => ({ ...message, id: message.id }))}
      columns={columns}
      pageSizeOptions={[5, 10, 25]}
      initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
      autoHeight
      onRowClick={(params) => goToMessage(params.id)}
      sx={{ cursor: "pointer", '& .MuiDataGrid-row:hover': { backgroundColor: '#f1f1f1' } }}
    />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>
          Are you sure you want to send this message to
          {tab === "Groups" ? ` ${selectedGroups.length} group(s)?` :
            ` ${pasteList.split(pasteList.includes(",") ? "," : "\n")
              .map((row) => row.trim())
              .filter(Boolean).length} recipient(s)?`}
        </DialogTitle>
        <DialogContent>
          <Card sx={{
              padding: 1,
              borderRadius: "24px 24px 6px 24px",
              backgroundColor: "#1976D2",
              color: "#fff",
              marginBottom: 2,
              }}>
            <CardContent>
              <Typography variant="body1" component="p" sx={{ whiteSpace: 'pre-wrap' }}>
                {messageBody}
              </Typography>
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)} color="secondary" disabled={isSubmitting}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} endIcon={<SendIcon />} disabled={isSubmitting}>
            {isSubmitting ? "Sending..." : "Send"}
          </Button>
        </DialogActions>
      </Dialog>
        </AccessContainer>
    </>
  );  
};

export default Page;