import React, { useState, useEffect } from "react";
import { useRoutes } from "hookrouter";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import "./index.css";
import AuditJournal from "./components/helix-audit/JournalItemTable";
import AuditCaseStatus from "./components/helix-audit/CaseStatus";
import AuditCaseJournal from "./components/helix-audit/CaseJournal";
import AuditClient from "./components/helix-audit/Client";
import PracticeAudit from "./components/helix-audit/PracticeAudit";
import RLSCostCenterCodes from "./components/RLS/RLSCostCenterCodes";
import ReportSecurity from "./components/RLS/ReportSecurity";
import CallFlowLayout from "./components/call-flow/CallFlow";
import CallFlowLayoutHolidays from "./components/call-flow/CallFlowHolidays";
import ViewNumbers from "./components/call-flow/ViewNumbers"
import PaymentImport from "./components/finance/PaymentImport";
import CreditControlTaskImport from "./components/finance/CreditControlTaskImport";
import LocumReport from "./components/freshcrm";
import LocumAuditAccess from "./components/freshcrm/LocumReportAccessAudit";
import PrivateHome from "./components/home/Private";
import PublicHome from "./components/home/Public";
import Sidebar from "./components/nav/Sidebar";
import NavBar from "./components/nav/NavBar";
import Footer from "./components/nav/Footer";
import Burger from "./components/nav/Burger";
import EnhancedSearch from "./components/helix-audit/EnhancedSearch";
import { useSessionStorage } from "./hooks/useSessionStorage";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import PartnerPracticePortalAudit from "./components/helix-audit/PartnerPracticePortal";
import Send from "./components/MessageHub/Send"
import BulkId from "./components/MessageHub/BulkId"
import Conversation from "./components/MessageHub/Conversation"
import Conversations from "./components/MessageHub/Conversations"


var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_AZURE_APPINSIGHTS_CONN,
    extensions: [reactPlugin],
  },
});

function App() {
  const routes = {
    "/": () => <PrivateHome toggleSideBar={toggleSideBar} />,
    "/JournalAudit/:id?": ({ id }) => <AuditJournal recordId={id} />,
    "/CaseStatusAudit/:id?": ({ id }) => <AuditCaseStatus caseId={id} />,
    "/CaseJournalAudit/:id?": ({ id }) => <AuditCaseJournal caseId={id} />,
    "/CasePractice/:id?": ({ id }) => <PracticeAudit caseId={id} />,
    "/Client/:id?": ({ id }) => <AuditClient clientId={id} />,
    "/CallFlow/": () => <CallFlowLayout />,
    "/CallFlowHolidays/": () => <CallFlowLayoutHolidays />,
    "/ViewNumbers/": () => <ViewNumbers />,
    "/PaymentImport/": () => <PaymentImport />,
    "/CreditControlTaskImport/": () => <CreditControlTaskImport />,
    "/LocumReport/": () => <LocumReport />,
    "/LocumReportAccessAudit/": () => <LocumAuditAccess />,
    "/ReportSecurity/": () => <ReportSecurity user={user} />,
    "/ReportSecurity/:report?": ({ report }) => (
      <RLSCostCenterCodes report={report} user={user} />
    ),
    "/EnhancedSearch/": () => <EnhancedSearch />,
    "/PartnerPracticeAudit/": () => <PartnerPracticePortalAudit />,
    "/MessageHub/Send/": () => <Send/>,
    "/MessageHub/Bulk/:id?": ({ id }) => <BulkId  cosmosId={id}/>,
    "/MessageHub/Conversation/:id?": ({ id }) => <Conversation  conversationId={id}/>,
    "/MessageHub/Conversations/": () => <Conversations  />,
  };

  const [theme, setTheme] = useState({
    palette: {
      type: "light",
    },
  });
  const [isAuthenticated, userHasAuthenticated] = useSessionStorage(
    "userHasAuthenticated",
    false
  );
  const [user, setUser] = useSessionStorage("user", null);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    if (!user) getUserInfo();
    // appInsights.loadAppInsights();
  }, []);

  const logoutUser = () => {
    setUser(null);
    userHasAuthenticated(false);
  };

  const toggleSideBar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const muiTheme = createTheme(theme);

  async function getUserInfo() {
    try {
      const response = await fetch("/.auth/me");
      const payload = await response.json();
      const { clientPrincipal } = payload;

      if (clientPrincipal) {
        setUser(clientPrincipal);
        userHasAuthenticated(true);
      }
    } catch (error) {
      console.error("No profile could be found " + error?.message?.toString());
    }
  }

  const routeResult = useRoutes(routes);

  return (
    <div className="App">
      <Burger open={sidebarOpen} setOpen={toggleSideBar} />
      <MuiThemeProvider theme={muiTheme}>
        <NavBar
          user={user}
          logoutUser={logoutUser}
          toggleSideBar={toggleSideBar}
        />
        <main className="column">
          {isAuthenticated ? routeResult : <PublicHome />}
        </main>
        <Sidebar open={sidebarOpen} toggleSideBar={toggleSideBar} user={user} />
        <Footer />
      </MuiThemeProvider>
    </div>
  );
}

export default App;
